<template>
  <div>
    <Plan ref="planModal" @updatePlan="updatePlan" @deleteModel="deleteModel" />
    <div class="card">
      <div
        class="d-flex w-100 m-0  mt-4 pr-4"
        style="justify-content: space-between;align-items: center;"
      >
        <div class="px-4">
          <b><span style="font-size: x-large;">Model</span></b>
        </div>
        <div class="d-flex align-items-center">
          <div v-if="selected.length > 1" class="d-flex align-items-center">
            <div
              class="h-100 d-flex align-items-center image-wrapper-active"
              style="padding: 16px 0"
              @click="$emit('enableCompare')"
            >
              <div class="tooltip-wrapper">Compare</div>
              <img
                src="@/assets/Icons/checkbox-multiple-blank-fill.svg"
                class="image"
              />
            </div>
          </div>
          <div v-if="selected.length === 1" class="d-flex align-items-center">
            <!---<div
              class="h-100 d-flex align-items-center"
              style="padding: 16px 0"
              :class="{ 'image-wrapper-active': overShare }"
              @mouseover="overShare = true"
              @mouseleave="overShare = false"
              @click="$refs.planModal.open('share')"
            >
              <div v-if="overShare" class="tooltip-wrapper">Share</div>
              <img
                v-if="overShare"
                src="@/assets/Icons/share-forward-fill.svg"
                class="image"
              />
              <img
                v-else
                src="@/assets/Icons/share-forward-line.svg"
                class="image"
              />
            </div>-->
            <div
              class="h-100 d-flex align-items-center"
              style="padding: 16px 0"
              :class="{ 'image-wrapper-active': overDelete }"
              @mouseover="overDelete = true"
              @mouseleave="overDelete = false"
              @click="$refs.planModal.open({ action: 'delete' })"
            >
              <div v-if="overDelete" class="tooltip-wrapper">Delete</div>
              <img
                v-if="overDelete"
                src="@/assets/Icons/delete-bin-6-fill.svg"
                class="image"
              />
              <img
                v-else
                src="@/assets/Icons/delete-bin-6-line.svg"
                class="image"
              />
            </div>
            <div
              class="h-100 d-flex align-items-center"
              style="padding: 16px 0"
              :class="{ 'image-wrapper-active': overView }"
              @mouseover="overView = true"
              @mouseleave="overView = false"
              @click="$emit('viewModelResult', selected)"
            >
              <div v-if="overView" class="tooltip-wrapper">View</div>
              <img
                v-if="overView"
                src="@/assets/Icons/eye-fill.svg"
                class="image"
              />
              <img v-else src="@/assets/Icons/eye-line.svg" class="image" />
            </div>
          </div>
          <div
            class="search-wrapper"
            :class="{ 'search-wrapper-collapse': selected.length }"
          >
            <b-input-group class="">
              <b-input-group-prepend>
                <img src="@/assets/Icons/searchDark.svg" class="image" />
              </b-input-group-prepend>
              <b-form-input
                placeholder="Search by Name"
                v-if="!selected.length"
                @input="searchTable"
                v-model="searchText"
              ></b-form-input>
              <b-input-group-prepend style="align-items: center;">
                <div class="Budgetloader" v-if="loader"></div>
              </b-input-group-prepend>
            </b-input-group>
          </div>
        </div>
      </div>
      <div class="overflow-auto">
        <b-table
          id="spaceTable"
          :items="items"
          :fields="fields"
          :select-mode="selectModeType"
          selectable
          @row-selected="onRowSelected"
          ref="selectableTable"
          class="mb-0"
          :total-rows="totalRows"
          :current-page="currentPage"
          :per-page="rowperPage"
        >
          <template #head()="data">
            <span class="column-heading">{{ data.label }}</span>
          </template>
          <template #head(selected)="">
            <span></span>
          </template>
          <template #head(marketKpi)="">
            <span class="column-heading">Market & KPI's</span>
          </template>

          <template #cell(selected)="{ rowSelected, index }">
            <input
              type="checkbox"
              class="form-check-input"
              :checked="rowSelected"
              @change="updateSelectRow(index, $event.target.checked)"
            />
          </template>
          <template #cell(planName)="data">
            <span class="column-data"> {{ data.item.planName }}</span>
          </template>
          <template #cell(marketKpi)="data">
            <div>
              <b-badge
                v-for="(item, index) in data.item.marketKpi"
                :key="index"
                class="mb-2 mr-2 column-data"
                >{{ item }}</b-badge
              >
            </div>
          </template>
          <template #cell(model)="data">
            <span class="column-data"> {{ data.item.model }}</span>
          </template>
          <template #cell(createdOn)="data">
            <span class="column-data"> {{ data.item.createdOn }}</span>
          </template>
        </b-table>
      </div>
    </div>
    <div v-if="items.length === 0" class="no-items-wrapper">
      <div class="text-center">
        <img src="@/assets/tech-life-media2.png" alt="" />
        <div v-if="items.length === 0" class="no-items-description">
          No plans have been added yet in your system
        </div>
        <!--<div v-else-if="" class="no-items-description">
          Oops!<br />
          Sorry to say, it seems like no plan has been found
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import Plan from "@/components/Modal/SaveModelModal.vue";
export default {
  name: "SpaceTable",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    loader: {
      type: Boolean,
      default: false,
    },
    selectModeType: {
      type: String,
      default: "multi",
    },
    filterBy: {
      type: Object,
      default: () => {},
    },
    rowperPage: {
      type: Number,
      default: 5,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    totalRows: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Plan,
  },
  watch: {
    filterBy(newVal) {
      this.filterOn = [];
      this.filterOn.push(newVal.id);
    },
  },
  data() {
    return {
      fields: [
        "selected",
        { key: "planName", sortable: false },
        { key: "marketKpi", sortable: false },
        { key: "model", sortable: false },
        // { key: "revenue", sortable: false },
        // { key: "budget", sortable: false },
        { key: "createdOn", sortable: true },
      ],
      selected: [],
      rows: null,
      perPage: 5,
      overDelete: false,
      overView: false,
      overShare: false,
      searchText: "",
      filterOn: [],
      timer: "",
    };
  },
  methods: {
    onRowSelected(items) {
      this.selected = items;
    },
    searchTable(e) {
      clearTimeout(this.timer);
      var self = this;
      this.timer = setTimeout(function() {
        self.$emit("searchInTable", e);
        clearTimeout(self.timer);
      }, 2000);
    },
    deleteModel() {
      this.$emit("deleteRow", this.selected);
    },
    updateSelectRow(index, val) {
      if (val) {
        this.$refs.selectableTable.selectRow(index);
      } else {
        this.$refs.selectableTable.unselectRow(index);
      }
    },
    getSelectedPage(e) {
      this.$emit("getSelectedPageEvent", e);
    },
    updatePlan(val) {
      if (val === "delete") {
        this.$emit("updateToast", {
          show: true,
          type: "error",
          message: "Our system has deleted your plan successfully!",
        });
      } else if (val === "share") {
        this.$emit("updateToast", {
          show: true,
          type: "success",
          message: "Yay, your plan has been shared successfully!",
        });
      }
    },
  },
};
</script>

<style scoped>
.column-heading {
  font-family: ProximaNovaBold;
  font-size: 16px;
  color: #222a37;
  margin: 40px 0;
}
.column-data {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #222a37;
  font-weight: normal;
}
.finance-heading {
  color: #9aafd4;
}
.badge {
  padding: 8px;
  background-color: #deffe6;
  border: 1px solid #81f89e;
}
>>> .table > tbody > tr {
  outline: none;
}
>>> .table > tbody > tr > td {
  padding: 24px;
}
>>> .table > tbody > .table-active > td {
  background-color: #eff5ff;
}
>>> .table > thead {
  box-shadow: 0 4px 7px #c9ced5;
}
>>> .table > thead > tr > th {
  outline: none;
  padding: 24px 24px;
}
>>> .table > :not(:last-child) > :last-child > * {
  border: none;
}
>>> .table.b-table > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right 1.5rem center;
}
.form-check-input {
  height: 24px;
  position: relative;
  top: 0px;
  margin: 0px;
  margin-right: 10px;
}

.form-check-input:checked {
  background-color: #222a37;
  border-color: #222a37;
}
.card {
  box-shadow: 0 0 8px 0 #c9ced5;
}
.image-wrapper-active {
  position: relative;
  background-color: #050505;
}
.tooltip-wrapper {
  width: 80px;
  height: 56px;
  background-color: #050505;
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  font-family: ProximaNovaRegular;
  clip-path: polygon(100% 0, 100% 53%, 50% 86%, 0 53%, 0 0);
  padding-top: 4px;
}
.search-wrapper {
  width: 318px;
  height: 60px;
  background-color: #eff5ff;
  display: flex;
  align-items: center;
  padding: 0;
}
.search-wrapper-collapse {
  width: 60px;
  background-color: transparent;
}
input[type="text"] {
  border: none;
  border-color: #c6ccd6;
  margin-left: 0;
  background-color: #eff5ff;
  box-shadow: none;
  height: 60px;
  font-size: 16px;
}

input::placeholder {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #9aafd4;
}
input:focus {
  background-color: #eff5ff;
  outline: none;
  box-shadow: none;
}
.image {
  padding: 0 16px;
}

>>> .page-item.disabled .page-link {
  background-color: transparent;
}
>>> .page-link {
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-family: ProximaNovaRegular;
  color: #9aafd4;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
}

>>> .page-link i {
  font-style: normal;
}

>>> .active > .page-link {
  background-color: transparent;
  border: 2px solid #050505;
  font-size: 16px;
  font-family: ProximaNovaRegular;
  color: #222a37;
  box-shadow: none;
}
.no-items-wrapper {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-items-description {
  opacity: 0.5;
  font-family: ProximaNovaBold;
  font-size: 16px;
  color: #222a37;
  margin-top: 42px;
}
</style>
